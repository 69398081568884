import { useTranslations } from "next-intl";

import { LoginComponentContext } from "~/shared/user/login-form";

import Dialog from "../common/dialog";
import Icon from "../common/icon";
import ModalGuest from "../login/modal-guest";
import styles from "./cart-products.module.scss";

type Props = {
  isOpen?: boolean;
  onClose: () => void;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
};

export default function CartLoginDialog({ isOpen, onClose, onAfterOpen, onAfterClose }: Props) {
  const t = useTranslations();
  return (
    <Dialog
      isOpen={isOpen}
      title={
        <>
          <Icon name={"skin-coach"} width={20} height={20} />
          <p>{t("generic.welcome_back")}</p>
        </>
      }
      onClose={onClose}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
      customStyles={styles}
    >
      <ModalGuest context={LoginComponentContext.cartModal} />
    </Dialog>
  );
}
