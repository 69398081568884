import classNames from "classnames";

import { useConfiguration } from "~/contexts/configuration";
import intlUtils from "~/utils/intl-utils";

import styles from "./product-price-algolia.module.scss";

type Props = {
  price: {
    formattedDiscountPerc?: string;
    oldValue?: number;
    value: number;
  };
  isProductGrid?: boolean;
};

export default function ProductPriceAlgolia(props: Props) {
  const priceFormatter = intlUtils.usePriceFormatter();
  const { configuration } = useConfiguration();

  if (Object.keys(props.price).length == 0) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <p className={styles.displayPrice}>
        {props.price?.value
          ? priceFormatter.currency(props.price.value, { currency: configuration.CurrencyID })
          : "n/a"}
      </p>
      {props.price.formattedDiscountPerc && (
        <p className={classNames(styles.discount, props.isProductGrid ? styles.discountProductGrid : undefined)}>
          -{Math.round(Number(props.price.formattedDiscountPerc))}%
        </p>
      )}
      {props.price?.oldValue && (
        <del
          className={classNames(
            styles.originalPrice,
            props.isProductGrid ? styles.originalPriceProductGrid : undefined
          )}
        >
          {priceFormatter.currency(props.price.oldValue, { currency: configuration.CurrencyID })}
        </del>
      )}
    </div>
  );
}
