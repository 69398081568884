import { Entry } from "contentful";
import Link from "next/link";
import { NextRouter, useRouter } from "next/router";

import Editorials from "~/types/editorials";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";
import textUtils from "~/utils/text-utils";

import styles from "./seo-navigation.module.scss";

type Props = {
  nav: Entry<Editorials.Nav>;
};

export default function SeoNavigation({ nav }: Props) {
  const router = useRouter();
  return (
    <nav className={styles.hiddenNav}>
      {nav.fields.navigationItems?.filter(isActiveEntry).map((heading) => {
        if (!isActiveEntry(heading.fields.link)) return null;
        return (
          <div key={heading.sys.id}>
            <Link prefetch={false} href={textUtils.sanitizeContentfulUrl(heading.fields.link, router)}>
              {heading.fields.link.fields.text}
            </Link>
            <SeoSubNavigation entry={heading} />
          </div>
        );
      })}
    </nav>
  );
}

type SeoSubNavigationProps = {
  entry: Entry<
    | Editorials.NavigationItemStandard
    | Editorials.NavigationItemCollectionsVariant
    | Editorials.NavigationItemServicesVariant
  >;
};

function recursiveMenu(
  section: Entry<Editorials.Link | Editorials.MenuGroup>[] | Entry<Editorials.MenuGroup>[],
  router: NextRouter
) {
  if (!section) return null;
  return (
    <ul>
      {section.map((item) => {
        if (!item) return null;

        return (
          <li key={item.sys.id}>
            {/* is a MenuGroup */}
            {"link" in item.fields && isActiveEntry(item.fields.link) && (
              <Link prefetch={false} href={textUtils.sanitizeContentfulUrl(item.fields.link, router)}>
                {item.fields.link.fields.text}
              </Link>
            )}
            {/* is a Link */}
            {("pageRef" in item.fields || "url" in item.fields) && (
              <Link prefetch={false} href={textUtils.sanitizeContentfulUrl(item as Entry<Editorials.Link>, router)}>
                {(item as Entry<Editorials.Link>).fields.text}
              </Link>
            )}
            {/* is a MenuGroup */}
            {"items" in item.fields &&
              item.fields.items &&
              item.fields.items?.filter(isActiveEntry).length > 0 &&
              recursiveMenu(item.fields.items?.filter(isActiveEntry), router)}
          </li>
        );
      })}
    </ul>
  );
}

function SeoSubNavigation({ entry }: SeoSubNavigationProps) {
  const router = useRouter();
  switch (entry.sys.contentType.sys.id) {
    case "navigationItemStandard":
      assertEditorialType<Editorials.NavigationItemStandard>(entry, "navigationItemStandard");
      const { menu, collections, services } = entry.fields;

      if (menu && !isActiveEntry(menu)) return null;

      const unionList = [
        ...(menu?.fields.highlight?.filter(isActiveEntry) || []),
        ...(menu?.fields.main?.filter(isActiveEntry) ?? []),
      ];

      return unionList && unionList.length > 0 ? (
        <ul>
          {unionList.map((section) => (
            <li key={section.sys.id}>
              {"link" in section.fields && isActiveEntry(section.fields.link) && (
                <Link prefetch={false} href={textUtils.sanitizeContentfulUrl(section.fields.link, router)}>
                  {section.fields.link.fields.text}
                </Link>
              )}
              {section.fields.items &&
                section.fields.items?.filter(isActiveEntry).length > 0 &&
                recursiveMenu(section.fields.items?.filter(isActiveEntry), router)}
            </li>
          ))}
          {collections && isActiveEntry(collections) ? (
            <li>
              {"link" in collections.fields && isActiveEntry(collections.fields.link) && (
                <Link prefetch={false} href={textUtils.sanitizeContentfulUrl(collections.fields.link, router)}>
                  {collections.fields.link.fields.text}
                </Link>
              )}
              {collections.fields.list &&
                collections.fields.list?.filter(isActiveEntry).length > 0 &&
                recursiveMenu(collections.fields.list?.filter(isActiveEntry), router)}
            </li>
          ) : null}
          {services && isActiveEntry(services) ? (
            <li>
              {services.fields.list &&
                services.fields.list?.filter(isActiveEntry).length > 0 &&
                recursiveMenu(services.fields.list?.filter(isActiveEntry), router)}
            </li>
          ) : null}
        </ul>
      ) : (
        <></>
      );
    case "navigationItemCollectionsVariant":
      assertEditorialType<Editorials.NavigationItemCollectionsVariant>(entry, "navigationItemCollectionsVariant");
      return recursiveMenu(entry.fields.list?.filter(isActiveEntry), router);

    case "navigationItemServicesVariant":
      assertEditorialType<Editorials.NavigationItemServicesVariant>(entry, "navigationItemServicesVariant");
      const collections2 = entry.fields.collections;

      return (
        <ul>
          <li>
            {"link" in entry.fields && isActiveEntry(entry.fields.link) && (
              <Link prefetch={false} href={textUtils.sanitizeContentfulUrl(entry.fields.link, router)}>
                {entry.fields.link.fields.text}
              </Link>
            )}
            {entry.fields.list &&
              entry.fields.list?.filter(isActiveEntry).length > 0 &&
              recursiveMenu(
                entry.fields.list
                  ?.filter(isActiveEntry)
                  .map((listItem) => listItem.fields.list?.filter(isActiveEntry))
                  .flat(),
                router
              )}
          </li>
          {collections2 && isActiveEntry(collections2) ? (
            <li>
              {"link" in collections2.fields && isActiveEntry(collections2.fields.link) && (
                <Link prefetch={false} href={textUtils.sanitizeContentfulUrl(collections2.fields.link, router)}>
                  {collections2.fields.link.fields.text}
                </Link>
              )}
              {collections2.fields.list &&
                collections2.fields.list?.filter(isActiveEntry).length > 0 &&
                recursiveMenu(collections2.fields.list?.filter(isActiveEntry), router)}
            </li>
          ) : null}
        </ul>
      );

    default: {
      return <></>;
    }
  }
}
