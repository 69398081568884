import classNames from "classnames";
import Link from "next/link";
import { useTranslations } from "next-intl";

import { LoginComponentContext } from "~/shared/user/login-form";
import appRoutes from "~/utils/app-routes";

import buttonStyles from "../common/button.module.scss";
import Login from "./login";
import styles from "./modal-guest.module.scss";

type ModalGuestProps = {
  context?: LoginComponentContext;
};

export default function ModalGuest({ context }: ModalGuestProps) {
  const t = useTranslations();

  return (
    <div className={styles.container}>
      <Login context={context ?? LoginComponentContext.headerModal} />
      <div className={styles.separator} />
      <div className={styles.registerWrapper}>
        <p className={styles.title}>{t("generic.do_not_have_account_yet")}</p>
        <p className={styles.paragraph}>{t("generic.register_now_take_part_KIKO_loyalty_program")}</p>
        <Link
          className={classNames(buttonStyles.button, buttonStyles.secondary, styles.registerButton)}
          href={`/${appRoutes.SIGN_UP}`}
        >
          {t("generic.register_here")}
        </Link>
      </div>
    </div>
  );
}
