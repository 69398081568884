import "@algolia/autocomplete-theme-classic";

import { BaseItem } from "@algolia/autocomplete-core";
import { autocomplete, AutocompleteOptions } from "@algolia/autocomplete-js";
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import { createLocalStorageRecentSearchesPlugin } from "@algolia/autocomplete-plugin-recent-searches";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { AbstractIntlMessages, IntlErrorCode, NextIntlClientProvider, useTimeZone, useTranslations } from "next-intl";
import React, { createElement, Fragment, useEffect, useMemo, useRef } from "react";
import { createRoot, Root } from "react-dom/client";

import BreakpointProvider, { useBreakpoint, useWindowSize } from "~/contexts/breakpoint";
import ConfigurationProvider, { useConfiguration } from "~/contexts/configuration";
import TextDirProvider from "~/contexts/text-dir";
import appRoutes from "~/utils/app-routes";

import Icon from "../common/icon";
import { popularPlugin } from "./plugins/popularPlugin";
import { productsPlugin } from "./plugins/productsPlugin";
import styles from "./search-dialog.module.scss";
import { searchClient } from "./searchClient";

type AlgoliaCustomAutocompleteProps = Partial<AutocompleteOptions<BaseItem>> & {
  className?: string;
  messages?: AbstractIntlMessages;
  isOpen?: boolean;
  setOpen?: () => void;
  onClose?: () => void;
};

function onError(error: { code: any }) {
  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    // Missing translations are expected and should only log an error
    console.warn(error);
  } else {
    // Other errors indicate a bug in the app and should be reported
    console.error(error);
  }
}

export default function AlgoliaCustomAutocomplete({
  className,
  messages,
  isOpen,
  setOpen,
  onClose,
  ...autocompleteProps
}: AlgoliaCustomAutocompleteProps) {
  const { algoliaIndexId, configuration, organizationUnitId } = useConfiguration();
  const t = useTranslations();
  const timezone = useTimeZone();
  const router = useRouter();
  const breakpoint = useBreakpoint();
  const { width } = useWindowSize();

  const autocompleteContainer = useRef<HTMLDivElement>(null);
  const panelRootRef = useRef<Root>();
  const rootRef = useRef<HTMLElement>();

  const suggestionsLimit = useMemo(() => {
    return breakpoint == "desktop" ? 5 : 3;
  }, [breakpoint]);

  const plugins = useMemo(() => {
    if (!searchClient && !algoliaIndexId) {
      return [];
    }

    const recentSearches = createLocalStorageRecentSearchesPlugin({
      key: "search",
      limit: suggestionsLimit,
      transformSource({ source, onRemove, onTapAhead }) {
        return {
          ...source,
          onSelect({ setIsOpen, event }) {
            if (!(event.target as HTMLElement).parentElement?.classList.contains("remove-item")) {
              setIsOpen(true);
            }
          },
          templates: {
            ...source.templates,
            item(params) {
              const { item } = params;
              return (
                <div className={classNames(styles.searchItem, styles.upp)}>
                  <p>{item.label}</p>
                  <button title={t("generic.fillQueryWith", { 0: item.label })} onClick={() => onTapAhead(item)}>
                    <Icon name="search-arrow" />
                  </button>
                  <button
                    title={t("generic.removeRecent")}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onRemove(item.id);
                    }}
                    className="remove-item"
                  >
                    <Icon name="trash" className="remove-item" />
                  </button>
                </div>
              );
            },
          },
        };
      },
    });

    const querySuggestions = createQuerySuggestionsPlugin({
      searchClient,
      indexName: `${algoliaIndexId}_query_suggestions`,
      getSearchParams() {
        return {
          hitsPerPage: suggestionsLimit,
        };
      },
      transformSource({ source, onTapAhead }) {
        return {
          ...source,
          onSelect({ setIsOpen }) {
            setIsOpen(true);
          },
          templates: {
            ...source.templates,
            item(params) {
              const { item, components } = params;
              return (
                <div className={styles.searchItem}>
                  <p className={styles.upperFirstLetter}>
                    {components ? <components.Highlight attribute="query" hit={item} /> : item.query}
                  </p>

                  <button title={t("generic.fillQueryWith", { 0: item.query })} onClick={() => onTapAhead(item)}>
                    <Icon name="search-arrow" />
                  </button>
                </div>
              );
            },
          },
        };
      },
    });

    return [
      recentSearches,
      querySuggestions,
      productsPlugin(algoliaIndexId, width!, t("generic.viewAllProducts"), router.locale!, (newItem) =>
        recentSearches.data?.addItem({ id: newItem, label: newItem })
      ),
      popularPlugin(algoliaIndexId, width!, router.locale!),
    ];
  }, [algoliaIndexId, router.locale, t, width, suggestionsLimit]);

  useEffect(() => {
    if (!autocompleteContainer.current) {
      return;
    }

    const autocompleteInstance = autocomplete({
      ...autocompleteProps,
      container: autocompleteContainer.current,
      renderer: { createElement, Fragment, render: () => {} },
      reshape({ sourcesBySourceId, sources, state }) {
        const {
          recentSearchesPlugin: recentSearches,
          querySuggestionsPlugin: querySuggestions,
          productsPlugin: products,
          popularPlugin: popular,
          ...rest
        } = sourcesBySourceId;

        const actualProducts = products?.getItems().length || 0;

        return [
          !state.query && recentSearches,
          querySuggestions,
          state.query && actualProducts > 0 && products,
          (!state.query || (state.query && actualProducts == 0)) && popular,
          ...Object.values(!rest),
        ].filter(Boolean);
      },
      render({ elements, state }, root) {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root;

          panelRootRef.current?.unmount();
          panelRootRef.current = createRoot(root);
        }

        const {
          recentSearchesPlugin: recentSearches,
          querySuggestionsPlugin: querySuggestions,
          productsPlugin: products,
          popularPlugin: popular,
        } = elements;

        panelRootRef.current.render(
          <NextIntlClientProvider
            locale={router.locale}
            timeZone={timezone}
            messages={router.query.debugLabels ? {} : messages}
            onError={onError}
          >
            <BreakpointProvider>
              <TextDirProvider>
                <ConfigurationProvider organizationUnitId={organizationUnitId} configuration={configuration}>
                  <>
                    {!state.query ? (
                      <>
                        <div
                          className={classNames(
                            { [styles.searchBox]: breakpoint == "desktop" },
                            styles.recentSuggestionWrapper
                          )}
                        >
                          {recentSearches && (
                            <div className={breakpoint == "mobile" ? styles.searchBox : styles.recentSuggestion}>
                              <h2 className={styles.searchHeading}>
                                <Icon name="recent" />
                                <span>{t("generic.recentSearches")}</span>
                              </h2>
                              {recentSearches}
                            </div>
                          )}
                          {querySuggestions && (
                            <div className={breakpoint == "mobile" ? styles.searchBox : styles.recentSuggestion}>
                              <h2 className={styles.searchHeading}>
                                <Icon name="lamp" />
                                <span>{t("generic.suggestions")}</span>
                              </h2>
                              {querySuggestions}
                            </div>
                          )}
                        </div>
                        {popular && (
                          <div className={styles.searchBox}>
                            <h2 className={styles.searchHeading}>{t("generic.noResultsTitleOverlay")}</h2>
                            {popular}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {breakpoint == "mobile" && querySuggestions && (
                          <div className={classNames(styles.recentSuggestionLive, styles.searchBox)}>
                            <h2 className={styles.searchHeading}>
                              <Icon name="lamp" />
                              <span>{t("generic.suggestions")}</span>
                            </h2>
                            {querySuggestions}
                          </div>
                        )}
                        <div className={breakpoint == "mobile" && !products ? undefined : styles.searchBox}>
                          {breakpoint == "desktop" && querySuggestions && (
                            <div className={classNames(styles.recentSuggestionLive, styles.searchBox)}>
                              <h2 className={styles.searchHeading}>
                                <Icon name="lamp" />
                                <span>{t("generic.suggestions")}</span>
                              </h2>
                              {querySuggestions}
                            </div>
                          )}
                          {products ? (
                            <>
                              <h2 className={styles.searchHeading}>{t("generic.products")}</h2>
                              {products}
                            </>
                          ) : (
                            <div className={styles.noResults}>
                              <h2>{t("generic.noresults")}</h2>
                              <p dangerouslySetInnerHTML={{ __html: t("generic.noresultsparagraph") }} />
                            </div>
                          )}
                          {popular && (
                            <div className={styles.searchBox}>
                              <h2 className={styles.searchHeading}>{t("generic.noResultsTitleOverlay")}</h2>
                              {popular}
                            </div>
                          )}
                        </div>
                        <div className={classNames(styles.searchBox, styles.searchAlert)}>
                          <p>
                            {t.rich("generic.askToKikoHelpCenter", {
                              link: (chunks) => {
                                return (
                                  <Link
                                    href={"/" + router.locale + appRoutes.CUSTOMER_HELP_CENTER}
                                    className={styles.link}
                                  >
                                    {chunks?.toString()}
                                  </Link>
                                );
                              },
                            })}
                          </p>
                        </div>
                      </>
                    )}
                  </>
                </ConfigurationProvider>
              </TextDirProvider>
            </BreakpointProvider>
          </NextIntlClientProvider>
        );
      },
      plugins,
      debug: true,
      onStateChange: (instance) => {
        if (instance.state.isOpen) {
          setOpen?.();
        }
      },
      onSubmit(params) {
        router.push(
          `/search?${algoliaIndexId}${encodeURIComponent("[query]")}=${encodeURIComponent(params.state.query)}`
        );
        onClose?.();
      },
      insights: true,
    });

    if (isOpen) {
      autocompleteContainer.current.querySelector("input")?.focus();
    }

    return () => autocompleteInstance.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plugins]);

  return <div className={className} ref={autocompleteContainer} />;
}
