import LoginAsCustomer from "~/components/login/login-as-customer";
import { useAuthContext } from "~/contexts/auth";
import CommonUtils from "~/utils/common-utils";

import styles from "./modal-guest.module.scss";

export default function ModalAdmin() {
  const { firebaseIdTokenResult } = useAuthContext();

  const enableModalAdmin =
    CommonUtils.parseBoolean(process.env.NEXT_PUBLIC_FIREBASE_ENABLE_IMPERSONATION) &&
    firebaseIdTokenResult?.claims.admin;

  if (!enableModalAdmin) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.separator} />
      <p className={styles.title}>Admin panel</p>
      <LoginAsCustomer />
      <div className={styles.separator} />
    </div>
  );
}
