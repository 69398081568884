import { Entry } from "contentful";

import HighlightedText from "~/components/common/highlighted-text";
import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import { isActiveEntry } from "~/utils/editorial-utils";

import styles from "./navigation-item-services-variant.module.scss";
import NavigationItemServicesVariantItem from "./navigation-item-services-variant-item";

type Props = {
  entry: Entry<Editorials.NavigationItemServicesVariantCategory>[];
};

export default function NavigationItemServicesVariant(props: Props) {
  if (!props.entry) return null;

  return (
    <div className={styles.container}>
      {props.entry.map((serviceCategory, indexCategory) => {
        const inspectorMode = contentfulUtils.useInspectorMode(serviceCategory);

        return (
          <div key={indexCategory} className={styles.serviceCategoryContainer}>
            {serviceCategory.fields.title ? (
              <div
                {...inspectorMode?.getProps("title")}
                className={
                  contentfulUtils.isHighlightText(serviceCategory.fields.title)
                    ? styles.serviceCategoryTitleHighlighted
                    : styles.serviceCategoryTitle
                }
              >
                <HighlightedText text={serviceCategory.fields.title} />
              </div>
            ) : null}
            <div className={styles.serviceCategoryWrapper}>
              {serviceCategory.fields.list?.filter(isActiveEntry).map((serviceItem, indexItem) => (
                <NavigationItemServicesVariantItem key={indexItem} entry={serviceItem} />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}
