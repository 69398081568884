import classNames from "classnames";

import Icon from "./icon";
import styles from "./quantity-selector.module.scss";

type Props = {
  onClick: (removing: boolean) => void;
  quantity: number;
  classNames?: string;
  disableRemove?: boolean;
  disableAdd?: boolean;
};

export default function SimpleQuantitySelector(props: Props) {
  return (
    <div className={classNames(styles.quantitySelector, props.classNames)}>
      <button
        type="button"
        className={styles.simpleQtyBtn}
        disabled={props.disableRemove}
        onClick={() => !props.disableRemove && props.onClick(true)}
      >
        <Icon name="minus" width={16} height={16} />
      </button>
      <p className={styles.textQty}>{props.quantity}</p>
      <button
        type="button"
        className={styles.simpleQtyBtn}
        disabled={props.disableAdd}
        onClick={() => !props.disableAdd && props.onClick(false)}
      >
        <Icon name="plus" width={16} height={16} />
      </button>
    </div>
  );
}
