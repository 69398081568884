import { zodResolver } from "@hookform/resolvers/zod";
import { KikCustomerLoginEvaFieldsSchema, KikCustomerLoginFields } from "@kikocosmeticsorg/uc-api-nest-common-fe";
import classNames from "classnames";
import { useTranslations } from "next-intl";
import { SubmitHandler, useForm } from "react-hook-form";

import Button from "~/components/common/button";
import Errors from "~/components/form/errors";
import InputField from "~/components/form/input-field";
import styles from "~/components/login/login.module.scss";
import { useAuthContext } from "~/contexts/auth";
import { useFormTranslations } from "~/hooks/use-form-translations";

export default function LoginAsCustomer() {
  const t = useTranslations();
  const { getFieldError } = useFormTranslations();
  const { authError, loginAsCustomer, logout, isLoading } = useAuthContext();
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<KikCustomerLoginFields>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldFocusError: false,
    resolver: zodResolver(KikCustomerLoginEvaFieldsSchema),
  });

  const onSubmit: SubmitHandler<KikCustomerLoginFields> = async (loginFields: KikCustomerLoginFields) => {
    return await loginAsCustomer(loginFields);
  };

  return (
    <div className={classNames(styles.loginContainer, styles.modalLoginContainer)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          {...register("EmailAddress")}
          type="email"
          label={t("generic.form.EmailAddress")}
          error={getFieldError(errors.EmailAddress?.message)}
          onClearErrors={() => clearErrors("EmailAddress")}
          className={styles.inputFieldContainer}
        />
        <InputField
          {...register("password")}
          type={"text"}
          label={t("generic.form.password_label")}
          error={getFieldError(errors.password?.message)}
          onClearErrors={() => clearErrors("password")}
          className={styles.inputFieldContainer}
        />
        <Errors error={authError} />
        <Button
          variant="primary"
          type="submit"
          disabled={isSubmitting || isLoading}
          loading={isSubmitting || isLoading}
          className={styles.loginButton}
        >
          {t("generic.login")}
        </Button>
      </form>
    </div>
  );
}
