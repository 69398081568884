import { useTranslations } from "next-intl";
import React from "react";

import Icon from "~/components/common/icon";
import styles from "~/components/login/login.module.scss";
import { SocialSignInButtonProps } from "~/shared/social-sign-in-button-props.interface";

import { SocialSignInConfig } from "./social-sign-in-config.interface";
import { socialSignInConfigs } from "./social-sign-in-configs.const";

export default function SocialSignInButton(props: SocialSignInButtonProps) {
  const t = useTranslations();
  const config: SocialSignInConfig = socialSignInConfigs[props.socialKey];

  return (
    <button onClick={() => props.onClick(props.socialKey)} className={styles.socialLogin} disabled={props.disabled}>
      <Icon name={config?.icon} width={16} height={16} />
      {!props.hideLabels ? " " + t(`generic.social.${props.socialKey.toLowerCase()}`) : null}
    </button>
  );
}
