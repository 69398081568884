import { hooks, state } from "@springtree/eva-sdk-react-recoil";
import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";
import Script from "next/script";
import { useTranslations } from "next-intl";
import { createContext, PropsWithChildren, useContext, useEffect, useRef, useState } from "react";

import styles from "~/components/common/coupon.module.scss";
import Icon from "~/components/common/icon";
import { PagePopup, PagePopupClose, PagePopupContent } from "~/components/common/pagePopup";
import { useCartWithHooks, useCartWithPendingStatus } from "~/hooks/use-cart";
import Constants from "~/utils/constants";

type ContextAttrs = {
  currentCoupon?: EVA.Core.OrderLineDto;
  applyCoupon: (code: string) => Promise<EVA.Core.AddDiscountToOrderResponse | undefined>;
  removeCoupon: () => Promise<EVA.Core.SimpleShoppingCartResponse | undefined>;
};

type ContextProps = {} & PropsWithChildren;

const Context = createContext<ContextAttrs | undefined>(undefined);

function _createAddCouponRequest(couponCode: string) {
  return {
    orderType: 0,
    payload: {
      CouponCode: couponCode,
    },
  };
}

function _findCouponEntryLine(cart: EVA.Core.ShoppingCartResponse | undefined) {
  return cart?.ShoppingCart.Lines.find((line) => line.DiscountCouponCode);
}

export default function GlobalCouponProvider(props: ContextProps) {
  const searchParams = useSearchParams();
  const router = useRouter();
  const { isLoadingCart } = useCartWithPendingStatus();
  const { cart, addCouponCode, cancelDiscountOrderLine, couponError, setCouponError } = useCartWithHooks();
  const refCart = useRef<EVA.Core.ShoppingCartResponse | undefined>(undefined);
  const [feedback, setFeedback] = useState("");
  const [currentCoupon, setCurrentCoupon] = useState<EVA.Core.OrderLineDto | undefined>(undefined);

  const t = useTranslations();
  const cartStaleService = hooks.useSetServiceStale({ serviceState: state.checkout.shoppingCartState });
  const refreshCart = () => {
    setTimeout(() => {
      cartStaleService();
    }, 1000);
  };

  const applyCoupon = async (code: string): Promise<EVA.Core.AddDiscountToOrderResponse | undefined> => {
    return addCouponCode(_createAddCouponRequest(code)).then((response) => {
      refreshCart();
      setCurrentCoupon(_findCouponEntryLine(cart));
      return response;
    });
  };

  const removeCoupon = async (): Promise<EVA.Core.SimpleShoppingCartResponse | undefined> => {
    if (!currentCoupon) {
      throw new Error("No coupon to remove");
    }
    return cancelDiscountOrderLine({
      payload: {
        OrderLineID: currentCoupon.ID,
      },
    })
      .then((response) => {
        if (!response?.Error) {
          setCurrentCoupon(undefined);
        }
        return response;
      })
      .catch((_err) => {
        return undefined;
      });
  };

  useEffect(() => {
    if (isLoadingCart || !router?.isReady) {
      return;
    }

    const paramCode = searchParams.get(Constants.COUPON_KEY);
    if (paramCode) {
      if (!cart) {
        setFeedback(t("generic.needsCart"));
        return;
      }

      if (refCart && refCart.current === undefined) {
        applyCoupon(paramCode)
          .then((response) => {
            const applied = response && response.DiscountID && response.DiscountIsApplied;
            setFeedback(t(applied ? "generic.codeApplied" : "error.invalid_coupon_combination"));
          })
          .catch((_err) => {
            // ignore and log after
          })
          .finally(() => {
            refreshCart();
            setCurrentCoupon(_findCouponEntryLine(cart));
          });
        refCart.current = cart;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCart, cart, router?.isReady]);

  // handle async error from AddDiscountCouponToOrder
  useEffect(() => {
    if (couponError) {
      const paramCode = searchParams.get(Constants.COUPON_KEY);

      switch (couponError.Type) {
        case "Discounts:InvalidCoupon":
          setFeedback(t("error.invalid_coupon", { code: paramCode }));
          break;

        case "Discounts:CouponAlreadyAdded":
          setFeedback(t("error.coupon_already_added"));
          break;

        default:
          setFeedback(t("error.invalid_coupon", { code: paramCode }));
          break;
      }
      setCouponError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponError, setCouponError]);

  return (
    <>
      {feedback && (
        <PagePopup initialOpen={true} closesOn="click">
          <PagePopupContent>
            <p className={styles.couponMessage}>{feedback}</p>
            <PagePopupClose>
              <Icon name="close" width={16} height={16} />
            </PagePopupClose>
          </PagePopupContent>
        </PagePopup>
      )}
      <Context.Provider value={{ applyCoupon, currentCoupon, removeCoupon }}>{props.children}</Context.Provider>
      <Script src="https://static.fanplayr.com/client/sw-load.js" data-path="/next-service-worker.js" />
    </>
  );
}

export function useGlobalCoupon() {
  const context = useContext(Context);

  if (!context) {
    throw new Error(`useCouponGlobal must be used within a GlobalCouponProvider`);
  }

  return context;
}
