import { useContentfulLiveUpdates } from "@contentful/live-preview/react";
import classNames from "classnames";
import { Entry } from "contentful";
import { useRouter } from "next/router";
import { AbstractIntlMessages, useTranslations } from "next-intl";
import { CSSProperties, useMemo } from "react";

import CartNotificationProvider from "~/contexts/cart-notification";
import WishlistProvider from "~/contexts/wishlist";
import Logger from "~/services/logger/logger";
import CrossCountry from "~/types/crossCountry";
import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import textUtils from "~/utils/text-utils";

import countries from "../../../public/json/countries.json";
import GlobalCouponProvider from "../../contexts/globalcoupon";
import CountryDialog from "../common/country-dialog";
import Icon from "../common/icon";
import LoyaltyPush from "../common/loyalty-push";
import SeoHead from "../common/seo-head";
import Footer from "./footer";
import Header from "./header";
import MiniFooter from "./mini-footer";
import Prefooter from "./prefooter";

type Props = {
  children: JSX.Element;
  navigation: Entry<unknown>;
  prefooter: Entry<unknown> | null;
  footer: Entry<unknown>;
  search: Editorials.SearchPage;
  page?: Editorials.BasicPageTemplate | null;
  navColor?: Editorials.navColor;
  inPpd?: boolean;
  mainStyle?: CSSProperties;
  mainClasses?: string;
  hidePrefooter?: boolean;
  miniFooter?: boolean;
  root?: CrossCountry.DetailedProduct;
  isHomepage?: boolean;
  productChildren?: CrossCountry.Product[];
  slugs?: Record<string, string> | null;
  messages?: AbstractIntlMessages;
};

export default function LayoutPage(props: Props) {
  const router = useRouter();
  const t = useTranslations();
  const isProductPage = router.pathname.includes("/p/") && !!props.root;

  const liveEntryHeader = useContentfulLiveUpdates(props.navigation);
  const liveEntryPrefooter = useContentfulLiveUpdates(props.prefooter);
  const liveEntryFooter = useContentfulLiveUpdates(props.footer);

  const seoProductDescription = useMemo(() => {
    if (props.root?.short_description) {
      const escapedShortDesc = textUtils.escapeHtmlParagraph(props.root.short_description);
      return `${props.root?.marketing_description || escapedShortDesc}. ${t("generic.buyNow")}`;
    }
    return;
  }, [props.root, t]);

  const allUnifiedCommerceLocales = useMemo(
    () =>
      countries.continents
        .flatMap((c) => c.countries)
        .filter((c) => c.isUnifiedCommerce)
        .flatMap((c) => c.cultures.map((c) => c.locale)),
    []
  );

  function canonicalUrl() {
    const baseUrl = `https://www.kikocosmetics.com/${router.locale}`;

    if (props.root?.product_id) {
      const slug = props.productChildren?.find((child) => child.isCanonical)
        ? props.productChildren.find((child) => child.isCanonical)?.slug
        : props.root.slug;
      const productId = props.productChildren?.find((child) => child.isCanonical)
        ? props.productChildren.find((child) => child.isCanonical)?.product_id
        : props.root.product_id;
      return `${baseUrl}/p/${slug}-${productId}/`;
    }

    return `${baseUrl}${router.asPath.split("?", 1)[0]}`;
  }

  const hrefLangs = useMemo(() => {
    const baseUrl = `https://www.kikocosmetics.com`;

    if (isProductPage || !props.slugs || Object.keys(props.slugs).length === 0) {
      return allUnifiedCommerceLocales.map((locale) => ({ locale, url: `${baseUrl}/${locale}${router.asPath}` }));
    }

    const output: { locale: string; url: string }[] = [];

    for (let [contentfulLocale, slug] of Object.entries(props.slugs)) {
      const routerLocales = Object.entries(contentfulUtils.localesMap)
        .filter(([_, onContentful]) => onContentful === contentfulLocale)
        .filter(([onRouter]) => allUnifiedCommerceLocales.includes(onRouter))
        .map(([onRouter, _]) => onRouter);

      //SEO issues: if the slug returned by the editorial pages is "homepage", remove the slug
      slug = slug.replace(process.env.NEXT_PUBLIC_HOMEPAGE_SLUG! || "", "");

      for (const locale of routerLocales) {
        const url = new URL(`${locale}/${slug}/`.replace("//", "/"), baseUrl);
        Logger.instance.debug("Built hreflang", url.href);
        output.push({ locale, url: url.href });
      }
    }
    return output;
  }, [allUnifiedCommerceLocales, isProductPage, props.slugs, router.asPath]);

  return (
    <CartNotificationProvider>
      <GlobalCouponProvider>
        <WishlistProvider>
          <>
            {props.page?.seoMetadata && (
              <SeoHead
                entry={props.page.seoMetadata}
                productDetail={props.root ? props.root : undefined}
                titleSuffix={t("generic.pageTitleSuffix")}
                productDescription={seoProductDescription}
                canonical={canonicalUrl()}
                hreflangs={hrefLangs}
              />
            )}
            <div style={{ position: "absolute", opacity: 0 }} aria-hidden="true">
              <Icon name="heart-path" />
            </div>
            <Header
              entry={liveEntryHeader}
              navColor={props.page?.navColor ? props.page.navColor : props.navColor || "transparent"}
              searchSuggestions={props.search.quickSearchSuggestions?.fields?.suggestions}
              inPpd={props.inPpd}
              isHomepage={props.isHomepage}
              messages={props.messages}
            />
            <main
              className={classNames({ contentBelowHeader: props.page?.contentBelowHeader || false }, props.mainClasses)}
              style={props.mainStyle}
            >
              {props.children}
              {!props.hidePrefooter && liveEntryPrefooter ? <Prefooter entry={liveEntryPrefooter} /> : null}
            </main>
            <CountryDialog />
            <LoyaltyPush />
            {props.miniFooter ? <MiniFooter entry={liveEntryFooter} /> : <Footer entry={liveEntryFooter} />}
          </>
        </WishlistProvider>
      </GlobalCouponProvider>
    </CartNotificationProvider>
  );
}
