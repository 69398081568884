import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";

import { searchClient } from "../searchClient";
import { ProductHit, ProductHitType } from "./productsPlugin";

export const popularPlugin = (algoliaIndexId: string, width: number, locale: string) => {
  return createQuerySuggestionsPlugin({
    searchClient,
    indexName: algoliaIndexId,
    getSearchParams() {
      return {
        query: "",
        hitsPerPage: width >= 1280 ? 8 : width >= 900 ? 6 : 4,
      };
    },
    transformSource({ source }) {
      return {
        ...source,
        sourceId: "popularPlugin",
        getItemInputValue({ item }) {
          return item.query;
        },
        onSelect({ setIsOpen }) {
          setIsOpen(true);
        },
        templates: {
          item({ item }) {
            return <ProductHit hit={item as unknown as ProductHitType} locale={locale} />;
          },
        },
      };
    },
  });
};
