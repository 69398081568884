import { useTranslations } from "next-intl";

import Icon from "~/components/common/icon";

import { UndoItem } from "./cart-products";
import styles from "./cart-products.module.scss";

type Props = {
  undoItem: UndoItem;
  undoAction: () => void;
  removeUndoItem: () => void;
};

export default function CartUndoItem(props: Props) {
  const t = useTranslations();

  return (
    <div className={styles.undoItem}>
      <div className={styles.content}>
        <p>
          {props.undoItem.productName}{" "}
          {t(
            props.undoItem.removedAction == "toWishlist"
              ? "generic.cart.undo.moved_to_wishlist"
              : props.undoItem.removedAction == "fromSfl"
              ? "generic.cart.undo.removed_from_savedforlater"
              : props.undoItem.removedAction == "toSfl"
              ? "generic.cart.undo.moved_to_savedforlater"
              : "generic.cart.undo.removed_from_bag"
          )}
        </p>
        <button className={styles.undoButton} onClick={props.undoAction}>
          {t("generic.undo")}
        </button>
      </div>
      <button className={styles.button} onClick={props.removeUndoItem} aria-label={t("a11y.cart.clear_undo_message")}>
        <Icon name={"close"} width={20} height={20} />
      </button>
    </div>
  );
}
