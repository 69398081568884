import { Entry } from "contentful";
import Link, { LinkProps } from "next/link";
import { useRouter } from "next/router";

import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import textUtils from "~/utils/text-utils";

type Props = Omit<LinkProps, "href"> & {
  entry: Entry<Editorials.Link>;
  className?: string;
  children?: React.ReactNode;
};

export default function NavigationLink(props: Props) {
  const { entry, children, ...rest } = props;
  const router = useRouter();

  if (!entry.fields.url && !entry.fields.pageRef) {
    return null;
  }

  const inspectorModeLink = contentfulUtils.useInspectorMode(entry);

  if (children) {
    return (
      <Link
        {...rest}
        prefetch={false}
        href={textUtils.sanitizeContentfulUrl(entry, router)}
        target={entry.fields.openOnANewTab ? "_blank" : undefined}
        {...inspectorModeLink?.getProps("text")}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <Link
        {...rest}
        prefetch={false}
        href={textUtils.sanitizeContentfulUrl(entry, router)}
        target={entry.fields.openOnANewTab ? "_blank" : undefined}
      >
        <span {...inspectorModeLink?.getProps("text")}>{entry.fields.text}</span>
      </Link>
    );
  }
}
