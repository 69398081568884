import classNames from "classnames";
import { Entry } from "contentful";

import Editorials from "~/types/editorials";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";

import styles from "./navigation-menu.module.scss";
import NavigationMenuGroup from "./navigation-menu-group";

type Props = {
  menu: Entry<Editorials.Menu>;
};

export default function NavigationMenu(props: Props) {
  return (
    <div className={styles.menuContainer}>
      {props.menu.fields.highlight && (
        <div className={styles.highlightCol}>
          {props.menu.fields.highlight?.filter(isActiveEntry).map((el, i) => (
            <NavigationMenuGroup key={i} menuGroup={el} />
          ))}
        </div>
      )}
      <div className={styles.mainCol}>
        {props.menu.fields.main?.filter(isActiveEntry).map((el, i) => {
          if (el.sys.contentType.sys.id == "menuColumn") {
            assertEditorialType<Editorials.MenuColumn>(el, "menuColumn");
            return (
              <div key={i} className={classNames(styles.mainGroup, styles.menuColumn)}>
                {el.fields.items?.filter(isActiveEntry).map((group, index) => (
                  <NavigationMenuGroup key={index} menuGroup={group} />
                ))}
              </div>
            );
          } else {
            assertEditorialType<Editorials.MenuGroup>(el, "menuGroup");
            return <NavigationMenuGroup classNames={styles.mainGroup} key={i} menuGroup={el} />;
          }
        })}
      </div>
    </div>
  );
}
