import classNames from "classnames";
import { useTranslations } from "next-intl";

import Editorials from "~/types/editorials";
import { isActiveEntry } from "~/utils/editorial-utils";

import Icon from "../common/icon";
import { LinkCard } from "../editorials/links-carousel/links-carousel";
import styles from "./cart-empty.module.scss";

type Props = {
  emptyLinks: Editorials.CartPage["emptyLinks"];
  hasContent?: boolean;
};

export default function CartEmpty({ emptyLinks, hasContent = true }: Props) {
  const t = useTranslations();

  return (
    <div className={classNames(styles.container, "beacon-cart-empty")}>
      {hasContent && (
        <>
          <Icon name="brokenheart-illustration" width={32} height={32} />
          <h2 className={styles.title}>{t("generic.cart.empty.title")}</h2>
          <p className={styles.description}>{t("generic.cart.empty.description")}</p>
          {emptyLinks ? (
            <div className={styles.links}>
              {emptyLinks.filter(isActiveEntry).map((link, i) => (
                <LinkCard key={`${link.sys.id}-${i}`} link={link} />
              ))}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
