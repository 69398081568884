import { KikSocialSignInKeys as SocialSignInKeys } from "@kikocosmeticsorg/uc-api-nest-common-fe";

import { SocialSignInConfig } from "./social-sign-in-config.interface";

export const socialSignInConfigs: Record<SocialSignInKeys, SocialSignInConfig> = {
  [SocialSignInKeys.APPLE]: {
    icon: "apple",
    key: SocialSignInKeys.APPLE,
  },
  [SocialSignInKeys.FACEBOOK]: {
    icon: "facebook-color",
    key: SocialSignInKeys.FACEBOOK,
  },
  [SocialSignInKeys.GOOGLE]: {
    icon: "google-color",
    key: SocialSignInKeys.GOOGLE,
  },
};
