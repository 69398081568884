import { Entry } from "contentful";

import Editorials from "~/types/editorials";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";

import NavigationLink from "../common/navigation-link";
import styles from "./navigation-menu-group.module.scss";

type Props = {
  menuGroup: Entry<Editorials.MenuGroup>;
  classNames?: string;
};

export default function NavigationMenuGroup(props: Props) {
  return (
    <div className={props.classNames}>
      {isActiveEntry(props.menuGroup.fields.link) ? (
        <NavigationLink entry={props.menuGroup.fields.link} className={styles.titleGroup} />
      ) : null}
      {props.menuGroup.fields.items?.filter(isActiveEntry) && (
        <div className={styles.itemsGroup}>
          {props.menuGroup.fields.items?.filter(isActiveEntry).map((el, i) => {
            switch (el.sys.contentType.sys.id) {
              case "link":
                assertEditorialType<Editorials.Link>(el, "link");
                return <NavigationLink key={el.sys.id} entry={el} />;

              case "menuGroup":
                assertEditorialType<Editorials.MenuGroup>(el, "menuGroup");
                if (!isActiveEntry(el.fields.link)) return null;
                return <NavigationLink key={el.fields.link.sys.id} entry={el.fields.link} />;

              default:
                return null;
            }
          })}
        </div>
      )}
    </div>
  );
}
