import classNames from "classnames";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { useFormatter, useTranslations } from "next-intl";
import { useMemo } from "react";

import { useConfiguration } from "~/contexts/configuration";
import { useCart } from "~/hooks/use-cart";
import appRoutes from "~/utils/app-routes";
import cartUtils from "~/utils/cart-utils";
import crossCountryUtils from "~/utils/crossCountry-utils";
import intlUtils from "~/utils/intl-utils";

import LinkWrapper from "../common/link-wrapper";
import styles from "./cart-subtotals.module.scss";

type Props = {
  onClose: () => void;
  emptyCart?: boolean;
};

export default function CartSubtotals(props: Props) {
  const t = useTranslations();
  const priceFormatter = intlUtils.usePriceFormatter();
  const { configuration } = useConfiguration();
  const cart = useCart();
  const router = useRouter();

  const totals = useMemo(
    () => cartUtils.calculateCartTotals(cart, configuration.PreferredPriceDisplayMode == 1),
    [cart, configuration.PreferredPriceDisplayMode]
  );
  const currency = cart?.ShoppingCart.CurrencyID ?? configuration?.CurrencyID;

  const reachedFreeShipping = cart && cart.ShoppingCart.Lines.length > 0 && totals.shippingAmount === 0;
  const lowestFreeShippingMethod = cartUtils.findShippingMethodWithLowestFreeShipping(cart);

  const notOnlyDigital =
    cart &&
    cart.ShoppingCart.Lines.length > 0 &&
    cart.ShoppingCart.Lines.some((el) => !el.IsCancelled && !el.IsDigitalGiftCard);

  const requiredForFreeShipping = lowestFreeShippingMethod?.amount ?? 0;
  const remainingForFreeShipping = requiredForFreeShipping - (totals.total - totals.shippingAmount);
  const freeShippingBarProgress = Math.max(Math.min(1 - remainingForFreeShipping / requiredForFreeShipping, 1), 0);

  const technicallyReachedFreeShipping = reachedFreeShipping || remainingForFreeShipping <= 0;

  return (
    <div className={styles.container}>
      <div className={classNames(styles.topLine, { [styles.transparent]: props.emptyCart })} />
      <div className={styles.totalContainer}>
        <p>{t("generic.total")}</p>
        <p> {priceFormatter.currency(totals.total, { currency: cart?.ShoppingCart.CurrencyID || currency })}</p>
      </div>
      {requiredForFreeShipping > 0 && notOnlyDigital && (
        <>
          <p className={styles.freeShippingText}>
            {technicallyReachedFreeShipping ? (
              <>{t("generic.cart.freeShippingReached", { method: lowestFreeShippingMethod?.method.Name })}</>
            ) : (
              <>
                {t.rich("generic.cart.freeShippingRemaining", {
                  span: (chunks) => <span className={styles.freeShippingMissing}>{chunks}</span>,
                  method: lowestFreeShippingMethod?.method.Name,
                  amount: priceFormatter.currency(remainingForFreeShipping, {
                    currency,
                  }),
                })}
              </>
            )}
          </p>
          <div className={styles.freeShippingMeter}>
            <div className={styles.freeShippingBar}>
              <motion.div
                className={styles.freeShippingFill}
                initial={{ width: 0 }}
                animate={{ width: technicallyReachedFreeShipping ? "100%" : freeShippingBarProgress * 100 + "%" }}
                transition={{ delay: 0.2 }}
              />
            </div>
          </div>
        </>
      )}
      <LinkWrapper className={styles.center} href={appRoutes.CART} onClick={props.onClose} lookAsButton="primary">
        {t("generic.gotobag", {
          0:
            cart && !cart.ShoppingCart.IsPaid && cart.ShoppingCart.TotalItems > 0
              ? `(${cart?.ShoppingCart.TotalItems})`
              : " ",
        })}
      </LinkWrapper>
    </div>
  );
}
