import { Entry } from "contentful";
import { useState } from "react";

import Grid from "~/components/common/grid";
import MediaAsset from "~/components/common/media-asset";
import Editorials from "~/types/editorials";

import styles from "./navigation-item-collections-variant.module.scss";
import NavigationItemCollectionsVariantItem from "./navigation-item-collections-variant-item";

type Props = {
  entry: Entry<Editorials.NavigationItemStandardCollectionsItem>[];
};

export default function NavigationItemCollectionsVariant(props: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);

  if (!props.entry) return null;

  return (
    <Grid>
      <div className={styles.collectionContainer}>
        {props.entry.map((el, index) => (
          <NavigationItemCollectionsVariantItem
            key={el.sys.id}
            entry={el}
            setCurrentIndex={setCurrentIndex}
            index={index}
          />
        ))}
      </div>
      <div className={styles.mediaContainer}>
        {props.entry.map((el, index) =>
          el.fields.imageLarge ? (
            <MediaAsset
              key={el.fields.imageLarge.sys.id}
              className={index === currentIndex ? styles.showMedia : styles.hideMedia}
              entry={el.fields.imageLarge}
            />
          ) : null
        )}
      </div>
    </Grid>
  );
}
